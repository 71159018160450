(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:HttpInterceptorSpinner
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('HttpInterceptorSpinner', HttpInterceptorSpinner);

  function HttpInterceptorSpinner($rootScope, $timeout, $q) {
    return {
      'request': function(config) {
        var promise = $timeout(function(){
          $rootScope.httpCallWaiting = true;
        }, 1000);
        $rootScope.timeoutPromises.push(promise);
        return config;
      },

      'response': function(response) {
        var promise = $rootScope.timeoutPromises.pop();
        $timeout.cancel(promise);
        if($rootScope.timeoutPromises.length === 0){
          $rootScope.httpCallWaiting = false;
        }
        return response;
      },

      'responseError': function (rejection) {
        var promise = $rootScope.timeoutPromises.pop();
        $timeout.cancel(promise);
        if ($rootScope.timeoutPromises.length === 0) {
          $rootScope.httpCallWaiting = false;
        }
        return $q.reject(rejection);
      }
    };
  }
}());
